// Generated by Framer (4175fb7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {c3b0XMvmu: {hover: true}};

const cycleOrder = ["c3b0XMvmu", "QuAJyreuP"];

const serializationHash = "framer-24lVR"

const variantClassNames = {c3b0XMvmu: "framer-v-405a5c", QuAJyreuP: "framer-v-1brc4qi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "c3b0XMvmu", "Variant 2": "QuAJyreuP"}

const getProps = ({height, iconColor, id, tap, width, ...props}) => { return {...props, jw9UJHEe9: iconColor ?? props.jw9UJHEe9 ?? "var(--token-4af095a4-d615-4489-95e2-c10a69fc6356, rgb(254, 39, 38))", OzXTe6oXu: tap ?? props.OzXTe6oXu, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "c3b0XMvmu"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;iconColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, OzXTe6oXu, jw9UJHEe9, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "c3b0XMvmu", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapj69qte = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (OzXTe6oXu) {
const res = await OzXTe6oXu(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-405a5c", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"c3b0XMvmu"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapj69qte} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"c3b0XMvmu-hover": {"data-framer-name": undefined}, QuAJyreuP: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-73tym4"} layoutDependency={layoutDependency} layoutId={"QiwpSmGBQ"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200, opacity: 0}} variants={{"c3b0XMvmu-hover": {backgroundColor: "var(--token-4af095a4-d615-4489-95e2-c10a69fc6356, rgb(254, 39, 38))", opacity: 1}}}/><motion.div className={"framer-n5rxdi"} data-border layoutDependency={layoutDependency} layoutId={"yPaGMMHod"} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-1e3c137c-9dd3-4de4-b429-cbc4841dbe4d, rgb(255, 239, 237)) /* {\"name\":\"Pale Light Red\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: jw9UJHEe9, borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200}} variants={{"c3b0XMvmu-hover": {backgroundColor: "rgb(255, 255, 255)"}, QuAJyreuP: {backgroundColor: "rgb(255, 255, 255)"}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-24lVR.framer-b96esm, .framer-24lVR .framer-b96esm { display: block; }", ".framer-24lVR.framer-405a5c { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-24lVR .framer-73tym4 { bottom: 8px; flex: none; left: 8px; overflow: visible; position: absolute; right: 8px; top: 8px; }", ".framer-24lVR .framer-n5rxdi { flex: none; height: 16px; left: calc(50.00000000000002% - 16px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 16px / 2); width: 16px; }", ".framer-24lVR.framer-v-1brc4qi.framer-405a5c, .framer-24lVR.framer-v-405a5c.hover.framer-405a5c { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-24lVR.framer-v-405a5c.hover .framer-73tym4 { bottom: 1px; left: 1px; right: 1px; top: 1px; }", ".framer-24lVR.framer-v-405a5c.hover .framer-n5rxdi { height: 20px; left: calc(50.00000000000002% - 20px / 2); top: calc(50.00000000000002% - 20px / 2); width: 20px; }", ".framer-24lVR[data-border=\"true\"]::after, .framer-24lVR [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QuAJyreuP":{"layout":["fixed","fixed"]},"Y8GJMVK2T":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"OzXTe6oXu":"tap","jw9UJHEe9":"iconColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerc4BCWaqUJ: React.ComponentType<Props> = withCSS(Component, css, "framer-24lVR") as typeof Component;
export default Framerc4BCWaqUJ;

Framerc4BCWaqUJ.displayName = "Nav Icon";

Framerc4BCWaqUJ.defaultProps = {height: 32, width: 32};

addPropertyControls(Framerc4BCWaqUJ, {variant: {options: ["c3b0XMvmu", "QuAJyreuP"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, OzXTe6oXu: {title: "Tap", type: ControlType.EventHandler}, jw9UJHEe9: {defaultValue: "var(--token-4af095a4-d615-4489-95e2-c10a69fc6356, rgb(254, 39, 38)) /* {\"name\":\"Red\"} */", title: "Icon Color", type: ControlType.Color}} as any)

addFonts(Framerc4BCWaqUJ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})